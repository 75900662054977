<template>
  <div class="wrap">
    <div class="title">
      <h1 class="title-text">东北运营区销售运行监控图</h1>
    </div>
    <div class="logOut" v-if="showMain" @click="logOut">退出</div>
    <div class="container" v-if="showMain">
      <div class="company-list">
        <div
          class="company-item"
          :class="{
            'company-item_active':
              companyIndex === index && item.factoryCode !== '0',
            'company-item_disable': item.factoryCode === '0',
          }"
          v-for="(item, index) in companyList"
          :key="index"
          @click="HandelCompany(item, index)"
        >
          <img
            src="./../assets/image/2.png"
            alt=""
            style="
              position: absolute;
              top: 0px;
              left: 0px;
              width: 20px;
              height: 15px;
            "
          />
          <span>{{ item.factoryName }}</span>
          <img
            src="./../assets/image/3.png"
            style="
              position: absolute;
              bottom: 0px;
              right: 0px;
              width: 20px;
              height: 15px;
            "
            alt=""
          />
        </div>
      </div>
      <div class="content">
        <div
          class="content-left"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <img
            src="./../assets/image/4.png"
            alt=""
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/5.png"
            alt=""
            style="
              position: absolute;
              top: 0;
              right: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/6.png"
            alt=""
            style="
              position: absolute;
              bottom: 0;
              right: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/7.png"
            alt=""
            style="
              position: absolute;
              bottom: 0;
              left: 0;
              width: 12px;
              height: 12px;
            "
          />
          <div class="search">
            <div class="search-input">
              <span class="lable">车牌号</span>
              <input type="text" v-model="formData.carNumber" />
            </div>
            <div class="search-input">
              <span class="lable">客户名称</span>
              <input type="text" v-model="formData.custName" />
            </div>
            <div class="button" @click="handleSeach">查询</div>
          </div>
          <div
            class="table-container"
            ref="tableRef"
            v-infinite-scroll="loadMore"
            infinite-scroll-throttle-delay="500"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="100"
            infinite-scroll-immediate-check="true"
          >
            <table
              cellpadding="0"
              cellspacing="0"
              width="100%"
              class="table"
              ref="table"
            >
              <thead>
                <tr>
                  <th width="100px">状态</th>
                  <th width="140px">客户名称</th>
                  <th width="130px">车牌号码</th>
                  <th width="220px">发货单号</th>
                  <th width="80px">发货重量</th>
                  <th>卸货地址</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in largeScreenList">
                  <tr
                    :key="index"
                    :ref="index"
                    @mouseenter="mouseenter"
                    style="position: relative; cursor: pointer"
                    @click="handleLargeScreen(item, index)"
                  >
                    <td
                      :style="{
                        color: item.orderStatus === 0 ? '#ffb541' : '#34ff7c',
                      }"
                    >
                      {{ item.orderStatus === 0 ? "配送中" : "已完成" }}
                    </td>
                    <td
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 0 10px;
                      "
                    >
                      {{ item.custName }}
                    </td>
                    <td>{{ item.carNumber }}</td>
                    <td
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 0 10px;
                      "
                    >
                      {{ item.orderCode }}
                    </td>
                    <td>{{ item.sendWeight }}吨</td>
                    <td
                      style="
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 0 10px;
                      "
                    >
                      {{ item.address }}
                    </td>
                  </tr>
                  <tr
                    v-if="largeScreenIndex === index"
                    :key="item.custId + 'd'"
                  >
                    <td colspan="6" style="padding: 0">
                      <div
                        class="table-detail"
                        :class="topShow ? 'table-detail_top' : ''"
                      >
                        <div class="table-detail-item">
                          <span>客户名称：{{ detailsData.custName }}</span>
                        </div>
                        <div class="table-detail-item">
                          <span>卸货地址：{{ detailsData.address }}</span>
                        </div>
                        <div class="table-detail-item">
                          <span style="width: 33.3%"
                            >出厂时间：{{ detailsData.leaveTime }}</span
                          >
                          <span style="width: 33.3%"
                            >进厂时间：{{ detailsData.leaveTime }}</span
                          >
                          <span
                            style="
                              width: 33.3%;
                              padding-left: 20px;
                              box-sizing: border-box;
                            "
                            >发货量：{{ detailsData.sendWeight }}吨</span
                          >
                        </div>
                        <div class="table-detail-item">
                          <span style="width: 33.3%"
                            >车牌号码：{{ detailsData.carNumber }}</span
                          >
                          <span style="width: 33.3%"
                            >发货单号：{{ detailsData.orderCode }}</span
                          >
                          <span
                            style="
                              width: 33.3%;
                              padding-left: 20px;
                              box-sizing: border-box;
                            "
                            >订单结束时间：{{ detailsData.unloadEndTime }}</span
                          >
                        </div>
                        <span
                          @click.stop="cancel"
                          class="cancel-icon"
                          style="
                            position: absolute;
                            right: 7px;
                            top: 0px;
                            font-size: 16px;
                            z-index: 100;
                            cursor: pointer;
                          "
                          >X</span
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-right">
          <img
            src="./../assets/image/4.png"
            alt=""
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/5.png"
            alt=""
            style="
              position: absolute;
              top: 0;
              right: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/6.png"
            alt=""
            style="
              position: absolute;
              bottom: 0;
              right: 0;
              width: 12px;
              height: 12px;
            "
          />
          <img
            src="./../assets/image/7.png"
            alt=""
            style="
              position: absolute;
              bottom: 0;
              left: 0;
              width: 12px;
              height: 12px;
            "
          />
          <div class="iframe-container" style="width: 100%; height: 100%">
            <iframe
              :src="iframeUrl"
              frameborder="0"
              style="width: 100%; height: 100%"
            ></iframe>
          </div>
          <div class="echart-container" v-if="showEcharts">
            <div id="main" style="width: 100%; height: 360px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="login" v-if="showLogin" :class="{ login_active: animeLogin }">
      <div class="login-input">
        <span class="lable">用户名</span>
        <input type="text" v-model="loginData.loginName" />
      </div>
      <div class="login-input">
        <span class="lable">密码</span>
        <input type="password" v-model="loginData.password" />
      </div>
      <div class="login-button" @click="loginHandle">登录</div>
      <span style="margin-top: 20px; font-size: 14px; color: #fff"
        >忘记密码？ 请联系管理员</span
      >
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      busy: false,
      iframeUrl: "",
      echarts: null,
      loginData: {
        loginName: "",
        password: "",
      },
      params: {
        pageIndex: 1,
        pageSize: 20,
        orderBy: "",
        sort: "",
      },
      formData: {
        factoryCode: "",
        custName: "",
        carNumber: "",
        orderStatus: "",
      },
      detailsData: {},
      companyList: [],
      companyIndex: 0,
      largeScreenList: [],
      largeScreenIndex: "",
      topShow: false,
      trackPointList: [],
      animeLogin: false,
      showMain: false,
      showEcharts: false,
    };
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.showLogin = false;
      this.showMain = true;
    } else {
      this.showLogin = true;
      this.showMain = false;
    }
    this.getDataList();
    this.getLargeScreenList();
    this.getIframePage();

    setInterval(() => {
      this.getList();
    }, 120000);
    window.addEventListener("resize", () => {
      this.echarts.resize();
    });
  },
  methods: {
    getDataList() {
      this.$axios.post("api/LargeScreen/GetSunnsyFactory").then((res) => {
        res.unshift({
          factoryName: "全部",
          factoryCode: "",
        });
        this.companyList = res;
      });
    },
    getIframePage() {
      this.iframeUrl = `/gps/LargeScreenMap.html?systoken=${window.localStorage.getItem(
        "token"
      )}&factoryCode=${this.formData.factoryCode}&mapstyle=maps1`;
    },
    getLargeScreenList() {
      const Obj = Object.assign(this.formData, this.params);
      this.$axios.post("api/LargeScreen/GetList", Obj).then((res) => {
        const { hasNextPage, dataSource } = res;
        this.largeScreenList = this.largeScreenList.concat(dataSource);
        if (hasNextPage) {
          this.busy = false;
        } else {
          this.busy = true;
        }
      });
    },
    // 循环请求
    getList() {
      this.loading = true;
      this.$refs.tableRef.scrollTop = 0;
      this.params.pageIndex = 1;
      const Obj = Object.assign(this.formData, this.params);
      this.$axios.post("api/LargeScreen/GetList", Obj).then((res) => {
        this.largeScreenList = res.dataSource;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    handleSeach() {
      this.$refs.tableRef.scrollTop = 0;
      this.getList();
    },
    mouseenter() {
      //  console.log(e)
    },
    cancel() {
      this.showEcharts = false;
      this.largeScreenIndex = -1;
      this.trackPointList = [];
      this.getEchartsData();
    },
    handleLargeScreen(data, idx) {
      this.topShow = false;
      this.largeScreenIndex = idx;
      this.detailsData = data;
      this.showEcharts = true;
      this.$nextTick(() => {
        this.getEchartsData();
      });
      setTimeout(() => {
        this.getOrderCarTrace(data.orderCode);
      }, 300);
    },
    getOrderCarTrace(orderCode) {
      this.echarts.showLoading({
        text: "loading",
        color: "#44d6f0",
        textColor: "#fff",
        maskColor: "transparent",
        zlevel: 0,
      });
      this.$axios
        .post("api/LargeScreen/GetOrderCarTrace", {
          pullTrackPointFilter: 10,
          orderCode,
        })
        .then((res) => {
          this.trackPointList = res.trackPointList;
          if (this.trackPointList.length === 0) {
            this.echarts.hideLoading();
          }

          this.getEchartsData();
        });
    },
    loadMore() {
      this.busy = true;
      setTimeout(() => {
        this.params.pageIndex++;
        this.getLargeScreenList();
      }, 500);
    },
    HandelCompany(data, idx) {
      if (data.factoryCode !== "0") {
        this.$refs.tableRef.scrollTop = 0;
        this.companyIndex = idx;
        this.formData.factoryCode = data.factoryCode;
        this.getIframePage();
        this.getList();
      }
    },
    loginHandle() {
      this.$axios.post("api/Login/Login", this.loginData).then((res) => {
        if (res) {
          this.animeLogin = true;
          window.localStorage.setItem("token", res);
          setTimeout(() => {
            this.getDataList();
            this.getLargeScreenList();
            this.$nextTick(() => {
              this.getEchartsData();
            });
            this.showMain = true;
          }, 2000);
        }
      });
    },
    logOut() {
      this.$axios.get("api/Login/LogOut").then(() => {
        window.localStorage.setItem("token", "");
        this.loginData.loginName = "";
        this.loginData.password = "";
        this.showMain = false;
        this.animeLogin = false;
        this.showLogin = true;
      });
    },
    getEchartsData() {
      var chartDom = document.getElementById("main");
      this.echarts = echarts.init(chartDom);

      const gpsTime = [];
      const speed = [];
      const weight = [];

      this.trackPointList.forEach((item) => {
        gpsTime.push(item.gpsTime);
        speed.push(item.speed);
        weight.push(item.weight);
      });

      if (gpsTime.length > 0) {
        this.echarts.hideLoading();
      }

      var option = {
        grid: {
          bottom: 80,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "#505765",
            },
          },
        },
        legend: {
          data: ["重量", "速度"],
          x: "center",
          y: "14",
          textStyle: {
            color: "#44d6f0",
            fontSize: "14",
            fontWeight: "bold",
          },
          backgroundColor: "#053157",
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            borderColor: "rgba(68, 161, 240, 0.2)",
            backgroundColor: "rgba(68, 161, 240, 0.2)",
            textStyle: {
              color: "#fff",
            },
            handleBackgroundColor: "rgba(68, 161, 240)",
            handleColor: "rgba(68, 161, 240)",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: false },
            axisLabel: {
              // X轴文字设置
              textStyle: {
                color: "#fff",
                fontSize: "12",
              },
              margin: 15, // 文字与X轴的距离
              // rotate: 45 // 文本旋转角度
            },

            // prettier-ignore
            data: gpsTime,
          },
        ],
        yAxis: [
          {
            name: "吨",
            nameTextStyle: {
              color: "#44d6f0",
              fontSize: "12",
            },
            axisLine: {
              lineStyle: {
                color: "none",
              },
            },
            type: "value",
            axisLabel: {
              // X轴文字设置
              textStyle: {
                color: "#44d6f0",
                fontSize: "12",
                // fontWeight: 'bold'
              },
              margin: 20, // 文字与X轴的距离
              // rotate: 45 // 文本旋转角度
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: "none",
              },
            },
          },

          {
            name: "km/h",
            nameTextStyle: {
              color: "#fff",
              fontSize: "12",
            },
            axisLine: {
              lineStyle: {
                color: "none",
              },
            },
            alignTicks: true,
            type: "value",
            inverse: false,
            axisLabel: {
              // X轴文字设置
              textStyle: {
                color: "#fff",
                fontSize: "12",
                // fontWeight: 'bold'
              },
              margin: 20, // 文字与X轴的距离
              // rotate: 45 // 文本旋转角度
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#102f5f"],
              },
            },
          },
        ],
        series: [
          {
            name: "速度",
            type: "line",
            areaStyle: {
              color: "rgba(68, 161, 240, 0.5)",
            },
            itemStyle: {
              color: "#44a1f0",
            },
            lineStyle: {
              width: 1,
            },
            emphasis: {
              focus: "series",
            },

            // prettier-ignore
            data: speed,
          },

          {
            name: "重量",
            type: "line",
            yAxisIndex: 1,
            lineStyle: {
              width: 2,
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: "#ffb541",
            },

            // prettier-ignore
            data: weight,
          },
        ],
      };

      option && this.echarts.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap {
  width: 100%;
  /* padding-top: 30px; */
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;
  background: url("./../assets/image/beijing.jpg") no-repeat;
  background-size: cover;
}
.title {
  width: 100%;
  height: 109px;
  line-height: 123px;
  text-align: center;
  box-sizing: border-box;
  background: url("./../assets/image/1.png") no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
.title-text {
  font-size: 34px;
  background-image: -webkit-linear-gradient(top, #00ccff, #00ccff, #0f5ed6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container {
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
}
.company-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
}

.company-item {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 145px;
  height: 40px;
  margin: 0 26px 25px 0;
  line-height: 40px;
  background-color: rgb(68, 214, 240, 0.1);
  border: 1px solid #3579bd;
}
.company-item span {
  color: #44d6f0;
  font-size: 18px;
}
.company-item.company-item_active {
  border: 1px solid #fff;
}

.company-item.company-item_active span {
  color: #fff !important;
}

.company-item.company-item_active img {
  z-index: -1;
}
.company-item:nth-child(11n) {
  margin-right: 0;
}
.company-item.company-item_disable {
  cursor: not-allowed;
  border: 1px solid #6d6d6d;
  background-color: rgb(245, 247, 250, 0.1);
}
.company-item.company-item_disable span {
  color: #a7aab1 !important;
}
.company-item.company-item_disable img {
  z-index: -1;
}

.content {
  display: flex;
  justify-content: space-between;
}
.content-left {
  position: relative;
  width: 940px;
  height: 786px;
  margin-right: 10px;
  border: 1px solid #3477bc;
  background-color: rgb(14, 43, 107, 0.1);
  box-shadow: 0 0 35px 0 rgb(102, 204, 255, 0.2) inset;
}
.content-right {
  position: relative;
  width: 900px;
  height: 786px;
  border: 1px solid #3477bc;
  background-color: rgb(14, 43, 107, 0.1);
}
.echart-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 390px;
  border-radius: 20px;
  background: url("./../assets/image/89.png") repeat-x;
}
.search {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 30px;
}
.search-input {
  margin-right: 25px;
  font-size: 16px;
  color: #fff;
}
.lable {
  margin-right: 22px;
}
.search-input input {
  width: 255px;
  height: 30px;
  background-color: rgb(68, 161, 240, 0.1);
  border: 1px solid #1c537b;
  outline: none;
  padding: 0 10px;
  color: #fff;
}
.button {
  width: 96px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  background-image: -webkit-linear-gradient(top, #0095ff, #1596f3, #0f5ed6);
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.table-container {
  height: 700px;
  overflow-y: auto;
  word-wrap: break-word;
}
.table {
  padding: 0;
  line-height: 45px;
  color: #fff;
  border-top: #143160 solid 1px;
  border-left: #143160 solid 1px;
}
table {
  table-layout: fixed;
  word-wrap: break-all;
}
th,
td {
  font-size: 15px;
  margin: 0;
  line-height: 28px;
  text-align: center;
  border-right: #143160 solid 1px;
  border-bottom: #143160 solid 1px;
  padding: 7px 15px;
  color: #fff;
  font-weight: normal;
  padding: 10px 0;
}
th {
  padding: 10px 0;
}

.table-detail {
  /* position: absolute;
  top: 45px;
  left: 0; */
  position: relative;
  word-wrap: break-word;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: rgb(0, 17, 55, 0.97);
  color: #44d6f0;
  font-size: 14px;
  border: 1px solid #258cbd;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: auto;
}
.table-detail.table-detail_top {
  top: -160px;
}
.table-detail-item {
  height: 34px;
  line-height: 34px;
  display: flex;
}
.table-detail-item span {
  text-align: start;
}
.login {
  position: fixed;
  left: 50%;
  top: 30%;
  width: 600px;
  transform: translateX(-300px);
  padding: 60px;
  display: flex;
  background-color: rgb(68, 161, 240, 0.1);
  flex-direction: column;
  border: 1px solid #1c537b;
  justify-content: center;
  align-items: center;
  transition: all 2s;
}
.login.login_active {
  transform: scale(0.5) translateX(280%) translateY(-140%);
  /* opacity: 0; */
  opacity: 0;
}
.login .login-input {
  margin-bottom: 30px;
}
.login-input {
  font-size: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.login-input input {
  width: 360px;
  height: 40px;
  margin-top: 10px;
  background-color: #5886af;
  border: 1px solid #1c537b;
  outline: none;
  padding: 0 10px;
  color: #fff;
}
.login-input span {
  font-size: 14px;
  color: #00ccff;
}
.login-button {
  margin-top: 30px;
  width: 360px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  background-color: rgb(68, 161, 240);
  border: 1px solid #1c537b;
  outline: none;
  padding: 0 10px;
  color: #fff;
  cursor: pointer;
}
.logOut {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 32px;
  border: 1px solid #3477bc;
  background-color: rgb(14, 43, 107, 0.1);
  color: #00ccff;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  opacity: 0;
}
.logOut:hover {
  opacity: 1;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  background-color: transparent;
}
</style>
