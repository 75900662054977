import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import infiniteScroll from 'vue-infinite-scroll'
import './styles/index.scss'
import { Loading } from 'element-ui';

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.use(infiniteScroll)
Vue.use(Loading);  
Vue.use(Loading.directive); 

axios.interceptors.request.use((config) => {
  if (window.localStorage.getItem('token')) {
    config.headers['SYSTOKEN'] = window.localStorage.getItem('token')
  }

  return config
})

axios.interceptors.response.use((response) => {
  const { code, data, msg } = response.data
  if (code === 1) {
    return data
  } else {
    alert(msg)
    return Promise.reject(msg)
  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')
